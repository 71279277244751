import React from "react"
import '../styles/index.scss'
import Layout from '../components/layout'
import ImageRotator from '../components/image-rotator'
import { graphql } from 'gatsby'

const HomePage = ({data}) => {
    return (
        <Layout>
            <ImageRotator images={data.allContentfulHomePageImage.nodes} />
        </Layout>
    );
}

export default HomePage;
export const query = graphql`
  query {
    allContentfulHomePageImage {
      nodes {
        id
        image {
            title
            fluid(maxWidth: 1024){
              sizes
              src
              srcSet
              ...GatsbyContentfulFluid_withWebp
            }
        }
      }
    }
  }
`
