import React, { useState, useEffect } from 'react'
import Img from "gatsby-image"
import ImageRotatorStyles from './image-rotator.module.scss'

const ImageRotator = ({images}) => {

    const delay = 7000;
    const [active, setActive] = useState(0);
    
    const rotateImage = () => {
        const newActive = (active + 1) % images.length;
        setActive(newActive)
    }

    useEffect(() => {
        let tim;
        if(images.length > 1) {
            tim = setInterval(rotateImage, delay);
        }
        return () => {
            clearInterval(tim);
        }
    }, [active])


    const NonStretchedImage = props => {
        if(typeof document == "undefined") return <Img {...props} />;

        const maxHeight = (90 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) / 100;
        const maxWidth = (90 * Math.max(document.documentElement.clientWidth, window.innerWidth || 0)) / 100;

        let height = maxWidth / props.fluid.aspectRatio;
        let width = maxHeight * props.fluid.aspectRatio;

        if(height > maxHeight) {
            height = maxHeight + 'px';
        } else if(width > maxWidth) {
            width = maxWidth + 'px';
        }

        let normalizedProps = props
        if (props.fluid) {
          normalizedProps = {
            ...props,
            style: {
              ...(props.style || {}),
              height,
              width,
              margin: "0 auto", // Used to center the image
            },
          }
        }
      
        return <Img {...normalizedProps} />
    }

    return (
        <NonStretchedImage className={ImageRotatorStyles.image} fluid={images[active].image.fluid} alt={images[active].image.title} />
    )
}

export default ImageRotator;
